import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import BannerTurmaDoChiquinhoMobile from '../../assets/img/2024/09/banner-site-mobile-mes-das-criancas.jpg';
import BannerCascoesChiquinhoMobile from '../../assets/img/2025/03/chiquinho-banner-site-mobile-institucional-01-04-2025.jpg';

import './styles.css';

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      arrows: false,
    };

    return (
      <Slider {...settings}>
       
       <div>
          <img src={BannerCascoesChiquinhoMobile} alt="Cascões Trufados + Nutella com Morango ou Banana + Chiquinho" />
        </div>

        <div>
          <a href='https://www.youtube.com/@turmadochiquinho' target='_blank'><img src={BannerTurmaDoChiquinhoMobile} alt="Turma do Chiquinho" /></a>
        </div>

      </Slider>
    );
  }
}
