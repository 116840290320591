import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import BannerTurmaDoChiquinhoDesktop from '../../assets/img/2024/09/banner-site-desktop-mes-das-criancas.jpg';
import BannerCascoesChiquinhoDesktop from '../../assets/img/2025/03/chiquinho-banner-site-desktop-institucional-01-04-2025.jpg';

import './styles.css';

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      arrows: false,
      draggable: false,
    };

    return (
      <Slider {...settings}>
 
        <div>
          <img src={BannerCascoesChiquinhoDesktop} alt="Cascões Trufados + Nutella com Morango ou Banana + Chiquinho" />
        </div>
        
        <div>
          <a href='https://www.youtube.com/@turmadochiquinho' target='_blank'><img src={BannerTurmaDoChiquinhoDesktop} alt="Turma do Chiquinho" /></a>
        </div>

      </Slider>
    );
  }
}
